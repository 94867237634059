import { graphql, useFragment } from 'react-relay/hooks';
import styled from '@emotion/styled';
import { SanityPage } from '@pafcloud/sanity-blocks';
import { PageContainer } from '@pafcloud/base-components';
import { usePlayerStatus } from '@pafcloud/contexts';
import { $buildEnv } from '@pafcloud/config/buildEnv';
import { PageSeoText } from '@pafcloud/block-components';
import type { PageWithQueryData } from '../lib/PageWithData';
import { NotFoundPage } from '../lib/NotFoundPage';
import type { pagesHomePageQuery } from './__generated__/pagesHomePageQuery.graphql';
import type { pagesHomePage_pageData$key } from './__generated__/pagesHomePage_pageData.graphql';

const SkeletonPage = styled(PageContainer)({
  minHeight: '50vh',
});

const homePageDataFragment = graphql`
  fragment pagesHomePage_pageData on Page {
    ...SanityPage_page
    ...PageSeoText_data
  }
`;

const HomePage: PageWithQueryData<pagesHomePageQuery> = (props) => {
  const page = useFragment<pagesHomePage_pageData$key>(homePageDataFragment, props.pageData.homePage);
  const isLoadingPlayer = usePlayerStatus() === 'LOADING';

  if (page == null) {
    return <NotFoundPage />;
  }

  if ($buildEnv.site === 'x3000.com' || $buildEnv.site === 'x3000.ee' || $buildEnv.site === 'x3000.lv') {
    if (isLoadingPlayer) {
      return <SkeletonPage />;
    }
  }

  return (
    <>
      <PageContainer>
        <SanityPage page={page} />
      </PageContainer>

      <PageSeoText data={page} />
    </>
  );
};

HomePage.query = graphql`
  query pagesHomePageQuery {
    homePage {
      ...pagesHomePage_pageData
    }
  }
`;

export default HomePage;
