/**
 * @generated SignedSource<<fc5f7af1d692f11b3019c0fa8d19de58>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type pagesHomePage_pageData$data = {
  readonly " $fragmentSpreads": FragmentRefs<"PageSeoText_data" | "SanityPage_page">;
  readonly " $fragmentType": "pagesHomePage_pageData";
};
export type pagesHomePage_pageData$key = {
  readonly " $data"?: pagesHomePage_pageData$data;
  readonly " $fragmentSpreads": FragmentRefs<"pagesHomePage_pageData">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "pagesHomePage_pageData",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SanityPage_page"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PageSeoText_data"
    }
  ],
  "type": "Page",
  "abstractKey": null
};

(node as any).hash = "b21914417d33486f0c131ffcda9fc9bc";

export default node;
